import './config/envConfig';
import { autoLoadModules } from 'react-core/config/utils';

autoLoadModules()
// eslint-disable-next-line no-unused-expressions
  .then(() => import(/* webpackChunkName: "agency" */ './app/app/app'))
  .then(
    async () => {
      // eslint-disable-next-line import/no-unresolved
      const { default: registerCachedSetting, reactDebug } = await import('@a24group/react-debug-settings/settings');
      registerCachedSetting('forceRoute', []);

      const setting = reactDebug.settings.forceRoute.get();

      if (setting) {
        if (Array.isArray(setting) && setting.length) {
          reactDebug.routerLoaders.agency(...setting);
          console.warn('Automatically served route from settings');
        } else if (!Array.isArray(setting)) {
          console.error('Automatically served route from settings is not an array '
            + 'e.g reactDebug.settings.forceRoute.set(["agency/forceRouteComponent"]);');
        }
      }
    },
  );
